<template>
    <BackArrow :pageTitles="{ heading: 'Vendors Bid', content: 'BACK TO Request for quote' }" />
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">{{ successMessage }}</span>
        </template>
    </success-toast>
    <main v-if="quote" class="bg-white mx-32 my-8 pt-10 pb-20 px-10 rounded-lg shadow text-sm">
        <h1 class="text-lg leading-9 font-bold capitalize">{{ quote.project_name }}</h1>
        <hr class="my-6" />
        <div class="flex text-sm mb-16">
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize">Deadline</h1>
                <div>{{ moment(quote.project_deadline).format('D MMM, YYYY') }}</div>
            </div>
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize">Date Created</h1>
                <div>{{ moment(quote.created_at).format('D MMM, YYYY') }}</div>
            </div>
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize">to</h1>
                <div>Vendors</div>
            </div>
        </div>
        <h1 class="text-lg font-bold capitalize">Vendors Bid</h1>
        <hr class="my-10" />
        <!-- Before admin approval -->
        <table
            v-if="
                quote.status.toLowerCase() === 'initiated' ||
                    quote.status.toLowerCase() === 'pending'
            "
            class="w-full table-fixed border border-borderColor capitalize mt-4"
        >
            <thead class="font-semibold text-left capitalize">
                <tr class="rounded-md">
                    <th class="w-1/2 border border-borderColor p-3">Expenditure Request</th>
                    <th class="w-1/2 border border-borderColor p-3">Vendor</th>
                    <th class="w-1/2 border border-borderColor p-3">Payment Preference</th>
                    <th class="w-1/4 border border-borderColor p-3">Quantity</th>
                    <th class="w-1/4 border border-borderColor p-3">Unit Price</th>
                    <th class="w-1/4 border border-borderColor p-3">net price</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="expenditureRequest in quote.rfq_expenditure_requests"
                    :key="expenditureRequest.id"
                >
                    <td class="border border-borderColor px-3  py-1 capitalize">
                        {{ expenditureRequest.product_name }}
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div
                            v-for="(vendor, index) in expenditureRequest.vendor_quotations"
                            :key="index"
                        >
                            <div class="py-1">{{ vendor.vendor }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div
                            v-for="(vendor, index) in expenditureRequest.vendor_quotations"
                            :key="index"
                        >
                            <div class="py-1">{{ vendor.payment_preference }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div
                            v-for="(vendor, index) in expenditureRequest.vendor_quotations"
                            :key="index"
                        >
                            <div class="py-1">{{ vendor.quantity }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div
                            v-for="(vendor, index) in expenditureRequest.vendor_quotations"
                            :key="index"
                        >
                            <div class="py-1">{{ vendor.unit_price.toFixed(2) }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div
                            v-for="(vendor, index) in expenditureRequest.vendor_quotations"
                            :key="index"
                        >
                            <div class="py-1">{{ vendor.net_price.toFixed(2) }}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- After admin approval -->
        <table
            v-if="
                quote.status.toLowerCase() !== 'initiated' &&
                    quote.status.toLowerCase() !== 'pending'
            "
            class="w-full table-fixed border border-borderColor text-xs mt-4"
        >
            <thead class="font-semibold text-left capitalize">
                <tr class="rounded-md">
                    <th class="w-1/2 border border-borderColor p-3">Expenditure Request</th>
                    <th class="w-1/4 border border-borderColor p-3">Vendors</th>
                    <th class="w-1/3 border border-borderColor p-3">Payment Preference</th>
                    <th class="w-1/4 border border-borderColor p-3">deadline</th>
                    <th class="w-1/4 border border-borderColor p-3">Quantity</th>
                    <th class="w-1/4 border border-borderColor p-3">unit price</th>
                    <th class="w-1/4 border border-borderColor p-3">net price</th>
                    <th class="w-1/4 border border-borderColor p-3">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="request in quote.rfq_expenditure_requests"
                    :key="request.id"
                    class="capitalize"
                >
                    <td class="border border-borderColor px-3">
                        {{ request.product_name }}
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div v-for="(vendor, index) in request.vendor_quotations" :key="index">
                            <div class="py-1">{{ vendor.vendor }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div v-for="(vendor, index) in request.vendor_quotations" :key="index">
                            <div class="py-1">{{ vendor.payment_preference }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor px-3">
                        {{ request.project_deadline }}
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div v-for="(vendor, index) in request.vendor_quotations" :key="index">
                            <div class="py-1">{{ vendor.quantity }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div v-for="(vendor, index) in request.vendor_quotations" :key="index">
                            <div class="py-1">{{ vendor.unit_price.toFixed(2) }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        <div v-for="(vendor, index) in request.vendor_quotations" :key="index">
                            <div class="py-1">{{ vendor.net_price.toFixed(2) }}</div>
                        </div>
                    </td>
                    <td class="border border-borderColor py-3">
                        <button
                            class="bg-primaryColor text-white p-1 rounded block mx-auto"
                            style="font-size:11px"
                        >
                            Create Purchase Order
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <submitRequest
            v-if="quote.status.toLowerCase() === 'initiated'"
            @click="submitRFQ"
            buttonText="Submit For Approval"
            class="block ml-auto mt-12"
        />
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import submitRequest from '@/components/ui/SubmitButton.vue'
import moment from 'moment'
import errorFunc from '@/util/error'

export default {
    name: 'quoteView',
    props: {
        quoteId: String,
    },
    components: { BackArrow, submitRequest },
    async mounted() {
        try {
            const { status, data } = await Request.getRequest(
                `request/quote/vendors/quotations/${this.quoteId}`
            )

            if (status >= 200 && status < 300) {
                this.quote = data.data
            }
        } catch (error) {
            errorFunc(error.response, this.$router)
            this.errorMessage = error.response.data.message
        }
    },
    data() {
        return {
            quote: null,
            moment,
            errorMessage: null,
            successMessage: null,
        }
    },
    methods: {
        async submitRFQ() {
            try {
                const {
                    status,
                    data,
                } = await Request.postRequest('vendors/quotations/submitted/by/procurement', {
                    rfq_id: this.quote.id,
                })

                if (status >= 200 && status < 300) {
                    this.successMessage = data.message
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async getAllQuotesApprovedByAdmin() {
            try {
                const { status, data } = await Request.getRequest(
                    `vendors/quotations/approved/by/admin/${this.quoteId}`
                )

                if (status >= 200 && status < 300) {
                    this.quote = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
